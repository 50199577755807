import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme, media } from '@dfds-ui/theme'
import { Headline } from '@dfds-ui/react-components'

import { LinkboxCarouselGrid } from '../Layout'
import { LinkboxCarouselItem } from '.'
import { EntryLink } from '../EntryLink'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: ${(props) =>
    props.turnIntoACarousel === false ? 'wrap' : 'nowrap'};
  overflow-x: auto;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.turnIntoACarousel === false ? '0' : '20px'};
  ::-webkit-scrollbar {
    height: 0.5em;
    width: 0.1em;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.divider.dark};
  }

  //Firefox only supported attributes
  scrollbar-color: ${theme.colors.divider.dark} transparent;

  ${media.greaterThan('l')`
    margin-bottom: 0;
  `}

  ${media.greaterThan('xl')`
    flex-wrap: wrap;
  `}

  > div {
    flex: 0 0 70%;
    min-width: 292px;
    margin-bottom: 20px;

    ${(props) =>
      props.turnIntoACarousel === false &&
      `
      flex: 0 0 100%;
      min-width: unset;
      margin-right: 0 !important;
    `};

    ${(props) =>
      props.turnIntoACarousel === false &&
      media.greaterThan('m')`
      &[lg="3"], &[md="6"], &[lg="6"] {
        flex: 0 0 calc(50% - 20px);
      }
      &[lg="4"] {
        flex: 0 0 calc(33.33% - 26.66px);
      }
    `};

    :not(:last-child) {
      margin-right: 20px;
    }

    ${media.greaterThan('l')`
      flex: 0 0 60%;
      min-width: unset;
      margin-bottom: 40px;
    `}

    ${media.greaterThan('xl')`
      margin-right: 0 !important;

      &[md="6"], &[lg="6"] {
        flex: 0 0 calc(50% - 20px);
      }
      &[lg="4"] {
        flex: 0 0 calc(33.33% - 26.66px);
      }
      &[lg="3"] {
        flex: 0 0 calc(25% - 30px);
      }
    `}
  }
`

const LinkboxCarousel = (props) => {
  const { title, turnIntoACarousel, link, carouselItemsCollection } = props
  const gridLayout = LinkboxCarouselGrid(carouselItemsCollection.items.length)

  return (
    <>
      {title && (
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Headline
            as={'h2'}
            css={css`
              margin-left: 20px;
              margin-right: 20px;

              ${media.greaterThan('m')`
              margin-left: 0;
              margin-right: 0;
            `}
            `}
          >
            {title}
          </Headline>
          {link && (
            <EntryLink
              css={css`
                display: none;

                ${media.greaterThan('m')`
                display: block;
              `}
              `}
              {...link}
            />
          )}
        </div>
      )}
      <Wrapper turnIntoACarousel={turnIntoACarousel}>
        {carouselItemsCollection?.items.map((carouselItem, index) => {
          return (
            <LinkboxCarouselItem
              key={carouselItem.sys.id}
              item={carouselItem}
              width={gridLayout[index]}
            />
          )
        })}
      </Wrapper>
    </>
  )
}

export const linkboxCarouselFragment = graphql`
  fragment LinkboxCarousel on contentful_LinkboxCarousel {
    __typename
    sys {
      id
    }
    title
    carouselItemsCollection(limit: 6) {
      items {
        sys {
          id
        }
        link {
          ...ItemLink
        }
        image {
          ...Asset
        }
        contentRef {
          title
          text
          showFromPrice
          fromPriceAmount
          currency
          placeCurrency
          fromHeadline
          pricePostText
        }
      }
    }
    turnIntoACarousel
    link {
      ...ItemLink
    }
  }
`

export default LinkboxCarousel
